import DeleteIcon from '@mui/icons-material/Delete';
import {Checkbox, IconButton, Typography} from '@mui/material';
import React, {useEffect, useState} from 'react';
import Spinner from 'react-activity/dist/Spinner';
import 'react-activity/dist/Spinner.css';
import {useNavigate} from 'react-router-dom';
import {NewClientModal} from '../../components/modal/new-client-modal';
import {useAuth} from '../../hooks/use-auth';
import {useBill} from '../../hooks/use-bill';
import {usePlaid} from '../../hooks/use-plaid';
import '../../styles/plaid.css';

const ClientDashboard = ({user}) => {
  const {
    state: {client_ids, clients, clients_loaded, users_loaded},
    getClients,
    updateClient,
    getUsers,
  } = useAuth();

  const [loading, setLoading] = useState(false);
  const [active, setActive] = useState(false);

  useEffect(() => {
    const loadClients = async () => {
      setLoading(true);
      await getClients();
      setLoading(false);
    };

    const loadUsers = async () => {
      setLoading(true);
      await getUsers();
      setLoading(false);
    };

    if (!users_loaded) {
      loadUsers();
    }
    if (!clients_loaded) {
      loadClients();
    }
  }, []);

  return (
    <div className="page-container">
      {loading ? (
        <div style={{display: 'flex', justifyContent: 'center'}}>
          <Spinner size={50} color={'gray'} speed={0.5} style={{top: 300}} />
        </div>
      ) : (
        <div>
          <div className="flex-row justify-between align-center">
            <Typography sx={{fontSize: 24, fontWeight: 'bold'}}>
              Clients - Services Provided
            </Typography>
            <button
              onClick={() => {
                console.log('CALLED', active);
                setActive(!active);
              }}>
              Add Client
            </button>
          </div>
          <table>
            <thead>
              <tr>
                <th>Customer Id</th>
                <th>Bank Accounts</th>
                <th>Active Bank Accounts</th>
                <th>Bill.com Accounts</th>
                <th>Active Bill.com Accounts</th>
                <th>Delete a Client</th>
              </tr>
            </thead>
            <tbody>
              {client_ids.map(id => {
                return <ClientItem key={id} id={id} />;
              })}
            </tbody>
          </table>
        </div>
      )}
      <NewClientModal active={active} setActive={setActive} />
    </div>
  );
};

const ClientItem = ({id}) => {
  const navigate = useNavigate();
  const {
    state: {clients, user_ids, users},
    updateClient,
    deleteClient,
    deleteUser,
    removeUserInstitutions,
  } = useAuth();
  const {
    state: {user_accounts, user_institutions},
    getUserAccounts,
    getUserInstitutions,
  } = usePlaid();
  const {
    state: {user_connections, connections},
    getUserConnections,
    removeUserConnections,
  } = useBill();

  const {plaid, bill} = clients[id];

  const [client_ids, setClients] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [cons, setConnections] = useState([]);

  useEffect(() => {
    const ids = user_ids.filter(user_id => {
      const {customerID} = users[user_id];
      if (customerID === id && user_accounts[id] === undefined) {
        getUserAccounts(user_id);
        getUserInstitutions(user_id);
        getUserConnections(user_id);
        return true;
      }
      return false;
    });

    setClients(ids);
  }, [user_ids]);

  useEffect(() => {
    let sum = [];
    client_ids.forEach(user_id => {
      const ids = user_accounts[user_id];
      sum = [...sum, ...(ids || [])];
    });
    setAccounts(sum);
  }, [user_accounts, client_ids]);

  useEffect(() => {
    let sum = [];
    client_ids.forEach(user_id => {
      const con_ids = user_connections[user_id];
      con_ids?.forEach(id => {
        const {organizations} = connections?.[id] ?? {};
        sum = [...sum, ...(organizations || [])];
      });
    });
    setConnections(sum);
  }, [user_connections, client_ids]);

  return (
    <tr key={id}>
      <td>{id}</td>
      <td>
        <Checkbox
          checked={plaid}
          onChange={async e => {
            const val = e.target.checked;
            await updateClient(id, {plaid: val});
          }}
        />
      </td>
      <td
        style={{color: 'blue', fontWeight: 'bold', cursor: 'pointer'}}
        onClick={() => {
          navigate(`/admin/client/accounts/${id}`);
        }}>
        {accounts?.length || 0} Accounts
      </td>
      <td>
        <Checkbox
          checked={bill}
          onChange={async e => {
            const val = e.target.checked;
            await updateClient(id, {bill: val});
          }}
        />
      </td>
      <td
        style={{color: 'blue', fontWeight: 'bold', cursor: 'pointer'}}
        onClick={() => {
          navigate(`/admin/client/accounts/${id}`);
        }}>
        {cons?.length || 0} Accounts
      </td>
      <td>
        <IconButton
          onClick={async () => {
            if (
              window.confirm('Are you sure you want to delete this client?')
            ) {
              // Proceed with delete operation
              console.log('Item deleted');

              // REMOVE CLIENT FROM CLIENT LIST
              await deleteClient(id);

              // FIND USERS
              const removed_users = user_ids.filter(user_id => {
                const {customerID} = users?.[user_id] ?? {};
                return customerID === id;
              });

              await Promise.all(
                removed_users.map(async user_id => {
                  const {emailAddress} = users?.[user_id] ?? {};
                  // DELETE USER OBJECT
                  await deleteUser(user_id, emailAddress);
                  // DELETE PLAID OBJECTS
                  const access_tokens = user_institutions[user_id];
                  await removeUserInstitutions(user_id, access_tokens);
                  // DELETE BILL OBJECTS
                  const connection_ids = user_connections[user_id];
                  await removeUserConnections(user_id, connection_ids);
                }),
              );
            } else {
              // Cancel delete operation
              console.log('Delete cancelled');
            }
          }}>
          <DeleteIcon />
        </IconButton>
      </td>
    </tr>
  );
};

export default ClientDashboard;
