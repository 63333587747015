import DeleteIcon from '@mui/icons-material/Delete';
import SendIcon from '@mui/icons-material/Send';
import {IconButton, Typography} from '@mui/material';
import React, {useEffect, useState} from 'react';
import Spinner from 'react-activity/dist/Spinner';
import 'react-activity/dist/Spinner.css';
import {useNavigate} from 'react-router-dom';
import FirestoreManager from '../../api/firebaseManager';
import {NewUserModal} from '../../components/modal/new-user-modal';
import {useAuth} from '../../hooks/use-auth';
import {useBill} from '../../hooks/use-bill';
import {usePlaid} from '../../hooks/use-plaid';
import '../../styles/plaid.css';

const UserDashboard = ({user}) => {
  const navigate = useNavigate();
  const {
    state: {users, users_loaded, mappings_loaded, mapping_ids, user_mappings},
    getUsers,
    getUserClientMappings,
  } = useAuth();

  const [loading, setLoading] = useState(false);
  const [active, setActive] = useState(false);
  const [sorted, setSorted] = useState([]);
  const [usersByEmail, setUsers] = useState({});

  const sort = field => {
    const s = [...mapping_ids].sort((a, b) => {
      const a_obj = user_mappings[a];
      const b_obj = user_mappings[b];

      if (
        typeof a_obj?.[field] === 'string' &&
        typeof b_obj?.[field] === 'string'
      ) {
        return a_obj[field].localeCompare(b_obj[field]);
      } else {
        return b_obj?.[field] - a_obj?.[field];
      }
    });
    setSorted(s);
  };

  useEffect(() => {
    const load = async () => {
      setLoading(true);
      if (!users_loaded) {
        await getUsers();
      }

      if (!mappings_loaded) {
        await getUserClientMappings();
      }
      setLoading(false);
    };

    load();
  }, []);

  useEffect(() => {
    sort('customerID');
  }, [mapping_ids]);

  useEffect(() => {
    const mapped = {};
    const user_list = Object.values(users);
    user_list.forEach(user => {
      const {emailAddress} = user || {};
      if (emailAddress) {
        mapped[emailAddress] = user;
      }
    });
    setUsers(mapped);
  }, [users]);

  return (
    <div className="page-container">
      {loading ? (
        <div style={{display: 'flex', justifyContent: 'center'}}>
          <Spinner size={50} color={'gray'} speed={0.5} style={{top: 300}} />
        </div>
      ) : (
        <div>
          <div className="flex-row justify-between align-center">
            <Typography sx={{fontSize: 24, fontWeight: 'bold'}}>
              Users
            </Typography>
            <button
              onClick={() => {
                setActive(!active);
              }}>
              Add User
            </button>
          </div>
          <table>
            <thead>
              <tr>
                <th
                  onClick={() => {
                    sort('customerID');
                  }}>
                  Customer Id
                </th>
                <th
                  onClick={() => {
                    sort('email');
                  }}>
                  User Email
                </th>
                <th
                  onClick={() => {
                    sort('name');
                  }}>
                  Name
                </th>
                <th>Signed Up</th>
                <th>Bank Accounts</th>
                <th>Bill.com Accounts</th>
                <th>Delete a User</th>
                <th>Send Password Reset</th>
              </tr>
            </thead>
            <tbody>
              {sorted.map(email => {
                return (
                  <MappedUserItem key={email} id={email} users={usersByEmail} />
                );
              })}
              {/* {sorted.map(id => {
                return <UserItem key={id} id={id} />;
              })} */}
            </tbody>
          </table>
        </div>
      )}
      <NewUserModal active={active} setActive={setActive} />
    </div>
  );
};

const MappedUserItem = ({id, users}) => {
  const navigate = useNavigate();

  const {
    state: {user_mappings},
    deleteUser,
  } = useAuth();
  const {
    state: {user_accounts, user_institutions},
    getUserAccounts,
    removeUserInstitutions,
  } = usePlaid();
  const {
    state: {user_connections, connections},
    getUserConnections,
    removeUserConnections,
  } = useBill();

  const [sending, setSending] = useState(false);

  const {customerID, email, name} = user_mappings?.[id] ?? {};
  const current = users?.[id] ?? {};
  const {uid} = current;

  const accounts = user_accounts?.[uid] ?? [];
  const cons = user_connections?.[uid] ?? [];

  let bill_orgs = 0;
  cons.forEach(id => {
    const link = connections[id];
    bill_orgs += link?.organizations?.length ?? 0;
  });

  useEffect(() => {
    if (!uid) {
      return;
    }

    if (user_accounts[uid] === undefined) {
      getUserAccounts(uid);
    }

    if (user_connections[uid] === undefined) {
      getUserConnections(uid);
    }
  }, [uid]);

  return (
    <tr key={email}>
      <td>{customerID}</td>
      <td>{email}</td>
      <td>{name}</td>
      <td>{uid ? 'Yes' : 'No'}</td>
      {uid ? (
        <>
          <td
            style={{color: 'blue', fontWeight: 'bold', cursor: 'pointer'}}
            onClick={() => {
              navigate(`/admin/user/accounts/${uid}`);
            }}>
            {accounts?.length || 0} Accounts
          </td>
          <td
            style={{color: 'blue', fontWeight: 'bold', cursor: 'pointer'}}
            onClick={() => {
              navigate(`/admin/user/accounts/${uid}`);
            }}>
            {bill_orgs} Accounts
          </td>
        </>
      ) : (
        <>
          <td>{accounts?.length || 0} Accounts</td>
          <td>{bill_orgs} Accounts</td>
        </>
      )}
      <td>
        <IconButton
          onClick={async () => {
            if (
              window.confirm(
                'Are you sure you want to delete this user and their accounts?',
              )
            ) {
              // Proceed with delete operation
              console.log('Item deleted');
              // DELETE USER OBJECT
              await deleteUser(id, email);

              // DELETE PLAID OBJECTS
              const access_tokens = user_institutions[id];
              await removeUserInstitutions(id, access_tokens);
              // DELETE BILL OBJECTS
              const connection_ids = user_connections[id];
              await removeUserConnections(id, connection_ids);
            }
          }}>
          <DeleteIcon />
        </IconButton>
      </td>
      <td>
        <IconButton
          onClick={async () => {
            if (sending) {
              return;
            }

            if (!email) {
              window.alert('No email associated with user.');
              return;
            }

            setSending(true);
            const {success, error, data} =
              await FirestoreManager.sendPasswordResetEmail(email);
            setSending(false);

            if (success) {
              window.alert('Password reset email was sent.');
            }

            if (error) {
              window.alert('Error sending password reset email.');
            }
          }}>
          {sending ? (
            <Spinner size={16} color={'gray'} speed={0.5} />
          ) : (
            <SendIcon />
          )}
        </IconButton>
      </td>
    </tr>
  );
};

const UserItem = ({id}) => {
  const navigate = useNavigate();

  const {
    state: {users},
  } = useAuth();
  const {
    state: {user_accounts},
    getUserAccounts,
  } = usePlaid();
  const {
    state: {user_connections, connections},
    getUserConnections,
  } = useBill();

  const {uid, customerID, emailAddress, fullName} = users?.[id] ?? {};

  const accounts = user_accounts?.[id] ?? [];
  const cons = user_connections?.[id] ?? [];

  let bill_orgs = 0;
  cons.forEach(id => {
    const link = connections[id];
    bill_orgs += link?.organizations?.length ?? 0;
  });

  useEffect(() => {
    // TODO: getBill.com Accounts
    if (user_accounts[id] === undefined) {
      getUserAccounts(id);
    }

    if (user_connections[id] === undefined) {
      getUserConnections(id);
    }
  }, []);

  return (
    <tr key={uid}>
      <td>{customerID}</td>
      <td>{emailAddress}</td>
      <td>{fullName}</td>
      {/* Add logic here to have yes or no for signup*/}
      <td> No </td>
      <td
        style={{color: 'blue', fontWeight: 'bold', cursor: 'pointer'}}
        onClick={() => {
          navigate(`/admin/user/accounts/${uid}`);
        }}>
        {accounts?.length || 0} Accounts
      </td>
      <td
        style={{color: 'blue', fontWeight: 'bold', cursor: 'pointer'}}
        onClick={() => {
          navigate(`/admin/user/accounts/${uid}`);
        }}>
        {bill_orgs} Accounts
      </td>
    </tr>
  );
};

export default UserDashboard;
