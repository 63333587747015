import React from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {useBill} from '../../hooks/use-bill';
import '../../styles/plaid.css';

export default function BillPage({user}) {
  const navigate = useNavigate();
  const {org_id} = useParams();
  const {
    state: {connections},
    removeConnection,
  } = useBill();

  const {organizations} = connections[org_id] || {};

  return (
    <div className="page-container">
      <div
        className="flex-row justify-between"
        style={{
          display: 'flex',
          justifyContent: 'space-between', // Align table and buttons horizontally
          alignItems: 'flex-start', // Align table and button container at the top
          width: '100%',
        }}>
        <table
          style={{
            flexGrow: 1, // Table takes up the remaining space
          }}>
          <thead>
            <tr>
              <th>Organization</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {organizations?.length &&
              organizations.map(item => {
                const {orgName} = item;
                return (
                  <tr key={orgName}>
                    <td>{orgName}</td>
                    <td>{'Active'}</td>
                  </tr>
                );
              })}
          </tbody>
        </table>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
            marginLeft: '36px',
          }}>
          <button
            className="disconnect"
            onClick={() => {
              navigate('/dashboard');
            }}
            style={{marginBottom: '16px'}}>
            Done
          </button>
          <button
            className="disconnect"
            onClick={async () => {
              const {success, error} = await removeConnection(org_id);

              if (success) {
                navigate('/dashboard');
              }
              if (error) {
                window.alert('Something went wrong. Try again later.');
              }
            }}>
            Disconnect
          </button>
        </div>
      </div>
    </div>
  );
}
