import {
  FormControl,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {useAuth} from '../../hooks/use-auth';
import {Modal} from './modal';

export const NewUserModal = ({active, setActive}) => {
  const {
    state: {client_ids, mapping_ids, clients_loaded},
    getClients,
    addUserMapping,
  } = useAuth();

  const [form, setForm] = useState({name: '', email: '', customerID: ''});

  useEffect(() => {
    const loadClients = async () => {
      await getClients();
    };

    if (!clients_loaded) {
      loadClients();
    }
  }, []);

  return (
    <Modal active={active} setActive={setActive}>
      <div className="box flex-column">
        <Typography sx={{textAlign: 'center', fontSize: '22px'}}>
          New User
        </Typography>
        <Grid container spacing={0}>
          <Grid
            item
            xs={4}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}>
            <Typography>Name:</Typography>
          </Grid>
          <Grid item xs={8}>
            <FormControl fullWidth>
              <TextField
                id="name"
                label="Name"
                placeholder="Name"
                value={form.name}
                type="text"
                onChange={evt => setForm({...form, name: evt.target.value})}
                variant="filled"
              />
            </FormControl>
          </Grid>
        </Grid>
        <br />
        <Grid container spacing={0}>
          <Grid
            item
            xs={4}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}>
            <Typography>User Email:</Typography>
          </Grid>
          <Grid item xs={8}>
            <FormControl fullWidth>
              <TextField
                id="email"
                label="Email"
                placeholder="Email"
                value={form.email}
                type="text"
                onChange={evt => setForm({...form, email: evt.target.value})}
                variant="filled"
              />
            </FormControl>
          </Grid>
        </Grid>
        <br />
        <Grid container spacing={0}>
          <Grid
            item
            xs={4}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}>
            <Typography>Customer ID:</Typography>
          </Grid>
          <Grid item xs={8}>
            <FormControl fullWidth>
              {/* <TextField
                id="customerID"
                label="Customer ID"
                placeholder="Customer ID"
                value={form.customerID}
                type="text"
                onChange={evt =>
                  setForm({...form, customerID: evt.target.value})
                }
                variant="filled"
              /> */}
              <Select
                id="simple-select"
                value={form.customerID}
                label="Customer Id"
                type="text"
                variant="filled"
                onChange={evt =>
                  setForm({...form, customerID: evt.target.value})
                }
                MenuProps={{
                  PaperProps: {
                    sx: {
                      backgroundColor: 'lightgray',
                      '& .MuiMenuItem-root': {
                        backgroundColor: 'lightgray',
                        '&:hover': {
                          backgroundColor: 'darkgray',
                        },
                      },
                    },
                  },
                }}>
                {client_ids?.length
                  ? client_ids.map(id => {
                      return (
                        <MenuItem
                          key={id}
                          value={id}
                          sx={{backgroundColor: 'light-gray'}}>
                          {id}
                        </MenuItem>
                      );
                    })
                  : null}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <br />
        <div className="flex-row justify-end">
          <button
            onClick={async () => {
              const {email, name, customerID} = form;

              if (!email || !name || !customerID) {
                window.alert('Fill out all fields to continue.');
                return;
              }

              if (mapping_ids.includes(email)) {
                window.alert('User already exists.');
                return;
              }

              await addUserMapping(form);
              setForm({name: '', email: '', customerID: ''});
              setActive(false);
            }}>
            Add User
          </button>
        </div>
      </div>
    </Modal>
  );
};
